import React from "react"

import * as styles from "./ugcExplainSection.module.scss"
import Container from "react-bootstrap/Container"

import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function UgcExplainSection() {

  return (
    <div className={styles.ugcExplainSection}>
      <h2 className="text-center">Kundenbewertungen</h2>
      <div className={`${styles.ratingStars} text-center`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
      </div>
      <div className={styles.explainPart}>
        <Container>
          <h2>Deine ehrliche Bewertung wird belohnt.<br />Denn sie hilft anderen Kund:innen.</h2>
          <p className={styles.subHl}>Du bist Kunde und hast noch nicht deine ehrliche Bewertung<br />abgegeben? Teile deine Erfahrung mit anderen!</p>
          <div className={styles.explanationList}>
            <div className={styles.explanationItem}>
              <div className={styles.explanationHeader}>
                <div className={styles.listNumber}>
                  1
                </div>
                <div className={`${styles.numberHl} fw-bold`}>
                  Bewertung <span className={styles.greenColor}>+ Video</span>
                </div>
              </div>
              <p>Dein Aufwand wird fürstlich belohnt. Du erhältst unsere <span className="fw-bold">Zubehör Box<br />komplett kostenlos</span> zu deiner nächsten Bestellung dazu.</p>
            </div>
            <div className={styles.explanationItem}>
              <div className={styles.explanationHeader}>
                <div className={styles.listNumber}>
                  2
                </div>
                <div className={`${styles.numberHl} fw-bold`}>
                  Bewertung <span className={styles.greenColor}>+ Foto</span>
                </div>
              </div>
              <p>Dein Aufwand wird ritterlich belohnt. Du erhältst den <span className="fw-bold">Zungenschaber</span> und<br />die <span className="fw-bold">Zahnseide kostenlos</span> zu deiner nächsten Bestellung dazu.</p>
            </div>
            <div className={styles.explanationItem}>
              <div className={styles.explanationHeader}>
                <div className={styles.listNumber}>
                  3
                </div>
                <div className={`${styles.numberHl} fw-bold`}>
                  Bewertung
                </div>
              </div>
              <p>Dein Aufwand wird gut belohnt. Du erhältst den <span className="fw-bold">Zungenschaber kostenlos</span><br />zu deiner nächsten Bestellung dazu.</p>
            </div>
          </div>
          <AnchorLink to={`${window.location.pathname+'#giveReview'}`} className={styles.ctaBtn}>Bewertung abgeben</AnchorLink>
        </Container>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import AllReviewsHeader from "../components/reviews/allReviewsHeader"
import UgcExplainSection from "../components/reviews/ugcExplainSection"
import IconSection from "../components/index/iconSection"
import AllReviews from "../components/reviews/allReviews"
import CookieConsent from "react-cookie-consent";

import Seo from "../components/layout/seo";

import useIsClient from '../useIsClient';

export default function Reviews() {

  const data = useStaticQuery(graphql`
    query {
      allDropsReview(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007", "7351701078250", "6619176370359", "7351700848874"]}}, rating: {gte: 1}}
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            body
            createdAt
            files
            id
            name
            rating
            title
            productIds {
              ids
            }
          }
        }
        totalCount
        sum(field: rating)
      },
      allDropsQuestion(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007", "7351701078250", "6619176370359", "7351700848874"]}}}
      ) {
        edges {
          node {
            body
            curated
            name
            title
            createdAt
            productQuestionAnswers {
              body
              createdAt
            }
          }
        }
      }
    }
  `)

  let productIds = {
  	"ids": [
  		"6899851854007",
  		"7351701078250",
  		"6619176370359",
  		"7351700848874"
  	]
  }

  const { isClient, key } = useIsClient();

  if ( !isClient ) return null;

  return (
    <Layout key={key} showBottom="false">
      <Seo title="Drops | Reviews | Toothpaste Drops" path="/" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <UgcExplainSection />
      <AllReviewsHeader />
      <IconSection background={true} firstTitle="4.9" firstText={<>> 500 positive<br />Rückmeldungen</>} firstStar={true} secondTitle="0%" secondText={<>Plastik<br />innen & außen</>} thirdTitle="100%" thirdText={<>Made in Germany<br />& Made by Us</>} fourthTitle="2x" fourthText={<>alle Sorten mit und<br />ohne Fluorid</>} />
      <AllReviews reviews={data.allDropsReview} questions={data.allDropsQuestion} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} productIds={productIds} />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}

import React from "react"

import * as styles from "./iconSection.module.scss"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function IconSection(props) {

  return (
    <div className={`${styles.iconSection} ${props.background ? styles.darkBackground : null}`}>
        <Container>
          <Row>
            <Col md={3} xs={6} className={styles.mobileFirstSection}>
              <p className={styles.iconTitle}>{props.firstTitle} {props.firstStar ? <svg xmlns="http://www.w3.org/2000/svg" className={styles.starIcon} width="30" height="30" viewBox="0 0 24 24"><path fill="#000000" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg> : null}</p>
              <p className={styles.iconDescription}>{props.firstText}</p>
            </Col>
            <Col md={3} xs={6}>
              <p className={styles.iconTitle}>{props.secondTitle}</p>
              <p className={styles.iconDescription}>{props.secondText}</p>
            </Col>
            <Col md={3} xs={6}>
              <p className={styles.iconTitle}>{props.thirdTitle}</p>
              <p className={styles.iconDescription}>{props.thirdText}</p>
            </Col>
            <Col md={3} xs={6}>
              <p className={styles.iconTitle}>{props.fourthTitle}</p>
              <p className={styles.iconDescription}>{props.fourthText}</p>
            </Col>
          </Row>
        </Container>
		</div>
  )
}

// extracted by mini-css-extract-plugin
export var ugcExplainSection = "ugcExplainSection-module--ugcExplainSection--1-jf8";
export var explainPart = "ugcExplainSection-module--explainPart--xUtkk";
export var subHl = "ugcExplainSection-module--subHl--HWD-P";
export var explanationList = "ugcExplainSection-module--explanationList--sIL-0";
export var explanationItem = "ugcExplainSection-module--explanationItem--CteR2";
export var explanationHeader = "ugcExplainSection-module--explanationHeader--KY8vp";
export var listNumber = "ugcExplainSection-module--listNumber--5zIMi";
export var numberHl = "ugcExplainSection-module--numberHl--Fe3Tk";
export var greenColor = "ugcExplainSection-module--greenColor--lF-os";
export var ctaBtn = "ugcExplainSection-module--ctaBtn--fjYjJ";
export var ratingStars = "ugcExplainSection-module--ratingStars--sLY4s";
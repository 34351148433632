// extracted by mini-css-extract-plugin
export var reviewsHeaderSection = "allReviewsHeader-module--reviewsHeaderSection--1C9Ph";
export var header = "allReviewsHeader-module--header--tLJZm";
export var surveyRatings = "allReviewsHeader-module--surveyRatings--RLTmn";
export var ratingBox = "allReviewsHeader-module--ratingBox--1KSfa";
export var ratingTitle = "allReviewsHeader-module--ratingTitle--mB7yj";
export var ratingStars = "allReviewsHeader-module--ratingStars--u8Aux";
export var ratingStar = "allReviewsHeader-module--ratingStar--MOrYm";
export var ratingWritten = "allReviewsHeader-module--ratingWritten--tOquw";
export var ratingInfo = "allReviewsHeader-module--ratingInfo--gsD1q";
export var circleStats = "allReviewsHeader-module--circleStats--Rb4Is";
export var circle = "allReviewsHeader-module--circle--syylx";
export var circleText = "allReviewsHeader-module--circleText--DLEBa";
import React, { useState, useEffect } from 'react';

import * as styles from "./allReviewsHeader.module.scss"

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ProductsService from "../../services/products.service";

import useIsClient from '../../useIsClient';

export default function AllReviewsHeader() {

  const { isClient, key } = useIsClient();

  const [statisticsData, setStatisticsData] = useState(null);
  const [tasteValue, setTasteValue] = useState(0);
  const [freshnessValue, setFreshnessValue] = useState(0);
  const [cleaningValue, setCleaningValue] = useState(0);
  const [foamValue, setFoamValue] = useState(0);
  const [volumeValue, setVolumeValue] = useState(0);
  const [consistencyValue, setConsistencyValue] = useState(0);

  useEffect(() => {
    // fetch statistics
    ProductsService.fetchReviewStatistics().then(
      response => {
        setStatisticsData(response.data.data);
        setTasteValue((response.data.data.taste/response.data.data.statisticsCount/20*100).toFixed(0));
        setFreshnessValue((response.data.data.freshness/response.data.data.statisticsCount/20*100).toFixed(0));
        setCleaningValue((response.data.data.cleaning/response.data.data.statisticsCount/20*100).toFixed(0));
        setFoamValue((response.data.data.foam/response.data.data.statisticsCount/20*100).toFixed(0));
        setVolumeValue((response.data.data.volume/response.data.data.statisticsCount/20*100).toFixed(0));
        setConsistencyValue((response.data.data.consistency/response.data.data.statisticsCount/20*100).toFixed(0));
      }
    );
  }, []);

  if ( !isClient ) return null;

  return (
    <div key={key} className={styles.reviewsHeaderSection}>
      <Container>
        <div className={styles.header}>
          <h1>Was unsere Kund:innen sagen</h1>
          <h2>und worauf sie wert legen</h2>
        </div>
        <Row className={styles.surveyRatings}>
          <Col md={{ span: 5, offset: 1 }}>
            <div className={styles.ratingBox}>
              <p className={styles.ratingTitle}>Toller Geschmack</p>
              <div className="d-flex align-items-center">
                <div className={styles.ratingStars}>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={tasteValue >= 1 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={tasteValue >= 2 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={tasteValue >= 3 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={tasteValue >= 4 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={tasteValue >= 5 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                </div>
                <div className={styles.ratingWritten}>
                  {tasteValue} / 5
                </div>
              </div>
              <p className={styles.ratingInfo}>*Durchschnittliche Bewertung</p>
            </div>
            <div className={styles.ratingBox}>
              <p className={styles.ratingTitle}>Reinigt sehr gut</p>
              <div className="d-flex align-items-center">
                <div className={styles.ratingStars}>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={cleaningValue >= 1 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={cleaningValue >= 2 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={cleaningValue >= 3 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={cleaningValue >= 4 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={cleaningValue >= 5 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                </div>
                <div className={styles.ratingWritten}>
                  {cleaningValue} / 5
                </div>
              </div>
              <p className={styles.ratingInfo}>*Durchschnittliche Bewertung</p>
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <div className={styles.ratingBox}>
              <p className={styles.ratingTitle}>Ideale Frische</p>
              <div className="d-flex align-items-center">
                <div className={styles.ratingStars}>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={freshnessValue >= 1 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={freshnessValue >= 2 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={freshnessValue >= 3 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={freshnessValue >= 4 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={freshnessValue >= 5 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                </div>
                <div className={styles.ratingWritten}>
                  {freshnessValue} / 5
                </div>
              </div>
              <p className={styles.ratingInfo}>*Durchschnittliche Bewertung</p>
            </div>
            <div className={styles.ratingBox}>
              <p className={styles.ratingTitle}>Kräftiger Schaum</p>
              <div className="d-flex align-items-center">
                <div className={styles.ratingStars}>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={foamValue >= 1 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={foamValue >= 2 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={foamValue >= 3 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={foamValue >= 4 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                  <svg xmlns="http://www.w3.org/2000/svg" className={styles.ratingStar} width="45" height="45" viewBox="0 0 24 24"><path fill={foamValue >= 5 ? '#fdd500' : '#fef2c1'} d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                </div>
                <div className={styles.ratingWritten}>
                  {foamValue} / 5
                </div>
              </div>
              <p className={styles.ratingInfo}>*Durchschnittliche Bewertung</p>
            </div>
          </Col>
        </Row>
        <div className={styles.circleStats}>
          <div>
            <div className={styles.circle} style={{ float: 'right' }}>
              <CircularProgressbar value={statisticsData !== null ? statisticsData.positive/statisticsData.moodCount*100 : 0} text={`${statisticsData !== null ? (statisticsData.positive/statisticsData.moodCount*100).toFixed(0) : 0}%`} />
              <p className={styles.circleText}>Positiv überrascht</p>
            </div>
          </div>
          <div>
            <div className={styles.circle} style={{ margin: '0 auto' }}>
              <CircularProgressbar value={statisticsData !== null ? statisticsData.rebuy/statisticsData.rebuyCount*100 : 0} text={`${statisticsData !== null ? (statisticsData.rebuy/statisticsData.rebuyCount*100).toFixed(0) : 0}%`} />
              <p className={styles.circleText}>Kaufen nochmal</p>
            </div>
          </div>
          <div>
            <div className={styles.circle} style={{ float: 'left' }}>
              <CircularProgressbar value={statisticsData !== null ? statisticsData.recommendation/statisticsData.recommendationCount*100 : 0} text={`${statisticsData !== null ? (statisticsData.recommendation/statisticsData.recommendationCount*100).toFixed(0) : 0}%`} />
              <p className={styles.circleText}>Empfehlen weiter</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
